import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const AvisoLegal = ({ s }) => {
	const intl = useIntl();
	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};
	return (
		<>
			<BodyClassName className="legal">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.avisolegal?.title)} />
					<main className="page lightgray-bg-01">
						<article className="box black-bg-02 title">
							<h1 className="prequel-font white-text align-center">Aviso legal</h1>
						</article>
						<ul className="breadcrumbs">
							<li className="link">
								<Link to="/" className="ltblue-text-02">
									Home
								</Link>
							</li>
							<li className="active">
								<p className="lightgray-text-07">Aviso legal</p>
							</li>
						</ul>
						<article className="box text">
							<p>
								En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de
								julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), el
								propietario de la web , le informa de lo siguiente:
							</p>
							<ul>
								<li>Denominación social: BANSAH & HOHOE S.L.</li>
								<li>NIF: B67143974</li>
								<li>
									Domicilio: CL PERE IV NUM.51 P.2 PTA.3 BARCELONA 08018. Inscrita en el Registro Mercantil de
									Barcelona Hoja B-514952 Tomo 46275 Folio 216
								</li>
							</ul>
							<p>
								Con los límites establecidos en la ley, BANSAH & HOHOE S.L.no asume ninguna responsabilidad
								derivada de la falta de veracidad, integridad, actualización y precisión de los datos o
								informaciones que contienen sus páginas web. Los contenidos e información no vinculan a BANSAH &
								HOHOE S.L. ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo pues se trata
								meramente de un servicio ofrecido con carácter informativo y divulgativo.
							</p>
							<p>
								Las páginas de Internet de BANSAH & HOHOE S.L. pueden contener enlaces (links) a otras páginas
								de terceras partes BANSAH & HOHOE S.L. no puede controlar. Por lo tanto, BANSAH & HOHOE S.L. no
								puede asumir responsabilidades por el contenido que pueda aparecer en páginas de terceros.
							</p>
							<p>
								Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este
								website son propiedad exclusiva de BANSAH & HOHOE S.L. o sus licenciantes. Cualquier acto de
								transmisión, distribución, cesión, reproducción, almacenamiento o comunicación pública total o
								parcial, deberá contar con el consentimiento expreso de BANSAH & HOHOE S.L.
							</p>
							<p>
								Asimismo, para acceder a algunos de los servicios que BANSAH & HOHOE S.L. ofrece a través del
								sitio web, deberá proporcionar algunos datos de carácter personal. En cumplimiento de lo
								establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril
								de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de
								datos personales y a la libre circulación de estos datos le informamos que, mediante la
								cumplimentación de los presentes formularios, sus datos personales quedarán incorporados y serán
								tratados en los ficheros de BANSAH & HOHOE S.L. con el fin de poderle prestar y ofrecer nuestros
								servicios así como para informarle de las mejoras del sitio Web.
							</p>
							<p>
								Le informamos también de que tendrá la posibilidad en todo momento de ejercer los derechos de
								acceso, rectificación, cancelación, oposición, limitación y portabilidad de sus datos de
								carácter personal, de manera gratuita mediante email a:{" "}
								<a href="mailto:hello@shareyourboard.app">hello@shareyourboard.app</a> o en la dirección: CL
								PERE IV NUM.51 P.2 PTA.3 BARCELONA 08018.
							</p>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(AvisoLegal);
